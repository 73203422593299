import Vue from "vue";
import {Constants, Util} from '@/core/utils'
import * as moment from 'moment';

function decimalFun(el, binding) {
  if (typeof binding.value != 'undefined' && binding.value != null) {
    const formattedValue = Util.formatNumber(binding.value);
    if (binding.arg) {
      const currency = Constants.Currencies[binding.arg.toUpperCase()]
      el.innerHTML = `${currency} ${formattedValue}`;
    } else
      el.innerHTML = formattedValue
  } else {
    el.innerHTML = null
  }
}

function localDateFun(el, binding) {
  if (typeof binding.value != 'undefined' && binding.value != null) {
    el.innerHTML = moment(binding.value).format(binding.arg !== 'hour' ? "DD/MM/YYYY" : "DD/MM/YYYY hh:mm A");
  } else {
    el.innerHTML = null
  }
}

function emptyFun(el, binding) {
  if (typeof binding.value != 'undefined' && binding.value != null) {
    el.innerHTML = binding.value;
  } else {
    el.innerHTML = "---"
  }
}

function titleFun(el, binding) {
  if (typeof binding.value != 'undefined' && binding.value != null) {
    el.innerHTML = Util.titleCase(binding.value);
  } else {
    el.innerHTML = null
  }
}


Vue.directive('localDate', {inserted: localDateFun, update: localDateFun});
Vue.directive('decimal', {inserted: decimalFun, update: decimalFun});
Vue.directive('empty', {inserted: emptyFun, update: emptyFun});
Vue.directive('title', {inserted: titleFun, update: titleFun});
