<template>
  <div class="custom-alert d-flex align-items-start align-items-md-center">
    <img :src="icon ? icon : require('@/core/assets/images/components/icon-alert.svg')" alt="Alerta" class="icon" />
    <p class="my-0" v-html="text">
    </p>
  </div>
</template>

<script>
export default {
    props: {
        text: String,
        icon: String
    }
};
</script>

<style lang="stylus" scoped>
@import "../styles/components/alert";
</style>
