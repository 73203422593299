<template>
  <div class="pagination d-flex justify-content-end">
    <div class="d-flex justify-content-center align-items-center flex-wrap">
      <span class="text d-inline-block">Mostrar filas:</span>
      <div class="position-relative">
        <select class="select" v-model="pageSizeSelected">
          <option v-for="p in pageSizes" :value="p" :key="`opt-${p}`">
            {{ p }}
          </option>
        </select>

        <img
            src="@/core/assets/images/components/select.svg"
            alt="Icono para seleccionar"
            class="select-icon"
        />
      </div>
      <span class="text d-inline-block"
      >{{ getLastResult() > 0  ? (page - 1) * pageSize + 1 : 0}} - {{ getLastResult() }} de
        {{ total }}</span
      >
      <div class="mt-3 mt-md-0"><a
          href="javascript:void(0)"
          class="arrow-margin"
          @click="changePage(page - 1)"
          :class="`${page === 1 ? 'disabled' : ''}`"
      >
        <img src="@/core/assets/images/components/left-arrow.svg" alt="Paginación izquierda">
      </a>
        <a
            href="javascript:void(0)"
            class="double-left"
            @click="changePage(1)"
            :class="`${page === 1 ? 'disabled' : ''}`"
        >
          <img src="@/core/assets/images/components/double-left-arrow.svg" alt="Paginación izquierda">
        </a>
        <a
            href="javascript:void(0)"
            class="arrow-margin"
            @click="changePage(pages)"
            :class="`${page === pages ? 'disabled' : ''}`"
        >
          <img src="@/core/assets/images/components/double-right-arrow.svg" alt="Paginación derecha">
        </a>
        <a
            href="javascript:void(0)"
            class="arrow-right"
            @click="changePage(page + 1)"
            :class="`${page === pages ? 'disabled' : ''}`"
        >
          <img src="@/core/assets/images/components/right-arrow.svg" alt="Paginación derecha">
        </a>
      </div>
    </div>
  </div>
</template>
<style>
</style>

<script>
export default {
  props: {
    total: {
      type: Number,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    pages: {
      type: Number,
      required: true,
    },
    changePage: {
      type: Function,
      required: true,
    },
    changePageSize: {
      type: Function,
      required: true,
    },
  },
  watch: {
    pageSizeSelected: function () {
      this.changePageSize(this.pageSizeSelected);
    },
  },
  data() {
    return {
      pageSizes: [20, 40, 60, 80, 100],
      pageSizeSelected: 20,
    };
  },
  methods: {
    getLastResult() {
      const self = this;
      if (
          self.total < self.pageSize ||
          self.pageSize * self.page > self.total
      ) {
        return self.total;
      } else {
        return self.pageSize * self.page;
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../styles/components/pagination';
</style>

