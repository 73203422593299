<template>
  <section class="loading d-flex justify-content-center align-items-center" :class="bg">
    <div class="text-center pl-md-5">
      <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
      </div>
      <p class="title text-white mt-3">Cargando, un momento por favor...</p>
    </div>
  </section>
</template>

<script>
export default {
    name: 'Loader',
    computed: {
        bg: function() {
            return this.$route.name === 'login' ? 'loading-bg-default' : 'loading-bg-primary'
        }
    }
}
</script>

<style lang="stylus" scoped>
@import "../styles/components/loader"
</style>