<template>
  <b-dropdown class="custom-dropdown user-dropdown" no-caret menu-class="custom-dropdown-menu-user" right v-if="user">
    <template #button-content>
      <i
        class="username"
        v-title="user.companyName"
      ></i>
      <b-avatar
        class="ml-1 user-image"
        size="2.1rem"
        src="~@/core/assets/images/sidebar/user.png"
      ></b-avatar>
    </template>
    <b-dropdown-text class="text-center px-3 dropdown-text">
      <b-avatar  class="mb-3 user-image-text" size="5rem" src="~@/core/assets/images/sidebar/user.png" badge-variant="light">
        <template #badge > <img class="imageIcon" src="~@/core/assets/images/image.svg"/></template>
      </b-avatar>
      <h2
        class="mb-2 dropdown-text-title"
        v-title="user.companyName"
      ></h2>
      <h4 class="mt-2 dropdown-text-detail">{{ user.email }}</h4>
      <b-dropdown-item @click="logout" class="m-2"
      >Cerrar Sesión</b-dropdown-item
      >
    </b-dropdown-text>
  </b-dropdown>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'UserDropdown',
  computed: {
    ...mapGetters(
        {
          user: 'auth/getUser',
        },
    )
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout').then(() => this.$router.push({name: 'login'}))
    }
  }
};
</script>

<style lang="stylus">
@import "../styles/components/user-dropdown"
</style>
