<template>
  <section class="vh-100 d-flex flex-column justify-content-between">
    <div>
      <Header/>
      <Banner/>
      <router-view></router-view>
    </div>
    <Footer/>
  </section>
</template>

<script>
import Header from './Components/Header'
import Banner from './Components/Banner'
import Footer from '@/core/components/PageFooter'

export default {
  components: {
    Header,
    Banner,
    Footer
  },
  name: "WelcomeView"
}
</script>

<style scoped lang="stylus">
</style>
