<template>
  <div class="welcome-page container">
    <div class="row justify-content-center">
      <div class="col-11 col-lg-9">
        <div class="pb-5 pb-md-0">
          <div class="container">
            <div class="row">
              <div class="col">
                <h3 class="title text-white text-center">Bienvenido a NAGA</h3>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-md-8">
                <SupplierForm/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import SupplierForm from "../Components/SupplierForm";

export default {
  components: {
    SupplierForm,
  }
}
</script>

<style lang="stylus" scoped>
@import '../Styles/welcome-page';
</style>
