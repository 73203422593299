<template>
  <div class="profile-page dashboard-container">
    <section class="dashboard-banner">
      <h1 class="dashboard-title">Mis datos</h1>
    </section>

    <div class="dashboard-content" v-if="user">
      <div class="dashboard-card">
        <div class="dashboard-card-body">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6 custom-col">
                <h3 class="card-subtitle">RUC</h3>
                <p class="card-text">{{  user.companyId }}</p>
              </div>

              <div class="col-md-6 custom-col">
                <h3 class="card-subtitle">Razón social</h3>
                <p class="card-text">{{  user.companyName }}</p>
              </div>
            </div>

            <div class="row">
              <div class="col custom-col">
                <h3 class="card-subtitle">Domicilio fiscal</h3>
                <p class="card-text">
                  {{  user.companyAddress }}
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col custom-col">
                <h3 class="card-subtitle">Nombre de contacto</h3>
                <p class="card-text">
                  {{  user.contactName }}
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 custom-col">
                <h3 class="card-subtitle">Correo electrónico</h3>
                <p class="card-text" v-empty="user.email"></p>
              </div>

              <div class="col-md-6 custom-col">
                <h3 class="card-subtitle">Teléfono</h3>
                <p class="card-text" v-empty="user.phone"></p>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 custom-col">
                <h3 class="card-subtitle">Celular</h3>
                <p class="card-text" v-empty="user.mobile"></p>
              </div>

              <div class="col-md-6 custom-col">
                <h3 class="card-subtitle">Cuenta de detracciones</h3>
                <p class="card-text" v-empty="user.detractionAccount"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="dashboard-card-body card-border-top mt-0 pt-0">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6 custom-col">
                <h3 class="card-subtitle">Banco</h3>
                <p class="card-text" v-empty="user.bank != null ? user.bank.name : null"></p>
              </div>

              <div class="col-md-6 custom-col">
                <h3 class="card-subtitle">Titular de la cuenta</h3>
                <p class="card-text" v-empty="user.bankHolder"></p>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 custom-col">
                <h3 class="card-subtitle">Número de cta. bancaria</h3>
                <p class="card-text" v-empty="user.bankAccount"></p>
              </div>

              <div class="col-md-6 custom-col">
                <h3 class="card-subtitle">CCI</h3>
                <p class="card-text" v-empty="user.bankInterAccount"></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="alert-container">
        <Alert
          text="Si desea modificar alguno de los datos por favor envíe un correo a
      <a href='mailto:proveedores@auren.com.pe'>proveedores@auren.com.pe</a> solicitando los cambios."
        />
      </div>
    </div>
  </div>
</template>

<script>
import Alert from "@/core/components/Alert";
import {mapGetters} from "vuex";

export default {
  name: "ProfilePage",
  components: {
    Alert,
  },
  methods: {
  },
  computed: {
    ...mapGetters(
        {
          user: 'auth/getUser',
        },
    ),
  },
  async created(){
    await this.$store.dispatch('auth/setUser');
  }
};
</script>

<style scoped lang="stylus">
@import '../Styles/profile-page';
</style>
