<template>
  <div class="voucher page-container">
    <div class="page-title-container">
      <h1 class="page-title">Cargar comprobante</h1>
    </div>

    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8">
          <b-card tag="article" no-body class="custom-card">
            <b-card-header
                header-tag="section"
                class="custom-card-header d-flex align-items-center"
            >
              <h3>Carga de archivos</h3>
            </b-card-header>
            <b-card-body class="custom-card-body">
              <Alert text="Debe cargar el comprobante en PDF y XML"/>

              <ul class="list list-unstyled">
                <li>
                  Tipo de comprobante:
                  <span class="text-secondary">{{ getVoucherType(payment.status) }}</span>
                </li>
                <li>
                  ID de pago:
                  <span class="text-secondary">{{ payment.code }}</span>
                </li>
                <li>
                  Monto de pago:
                  <span class="text-secondary" v-decimal:pen="payment.amount"></span>
                </li>
              </ul>

              <div class="custom-form">
                <FileInput :acceptFile="['.pdf', '.xml']" id-file="input-files" @onChange="onChange" :multiple="true"/>
                <form-error message="Debes cargar un archivo .PDF y un archivo .XML"
                            v-if="$v.files.$error"></form-error>
                <div class="form-group-buttons text-right">
                  <router-link
                      :to="{ name: 'payments' }"
                      class="button button-cancel"
                  >
                    Cancelar
                  </router-link>
                  <button class="button button-primary" type="submit" @click="submit">
                    Procesar
                  </button>
                </div>
                <div class="form-errors mt-3" v-if="errors.length > 0">
                  <Alert text="Por favor, solucione los siguientes problemas o caso contrario genera una nota de crédito para cargar una nueva factura."
                         :icon="require('@/core/assets/images/components/icon-alert-yellow.svg')" class="warning mb-2" />
                  <b><span class="error">Errores: {{ errors.length }}</span></b><br>

                  <span v-for="(e, i) in errors" class="error" :key="`${i}-error`">{{ e }}<br></span>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {minLength, maxLength, required} from "vuelidate/lib/validators";
import Alert from "@/core/components/Alert";
import FileInput from "@/core/components/FileInput";
import {Constants} from "@/core/utils";
import {VoucherService, PaymentService} from "@/core/services";
import FormError from "@/core/components/FormError";

export default {
  name: "UploadVoucherPage",
  props: {
    code: String
  },
  components: {
    Alert,
    FileInput,
    FormError
  },
  data() {
    return {
      payment: {},
      files: [],
      errors: [],
    };
  },
  validations: {
    files: {
      minLength: minLength(2),
      maxLength: maxLength(2),
      required
    },
  },
  methods: {
    getVoucherType(status) {
      if (status === Constants.PaymentStatus.PENDING.key)
        return Constants.VoucherType.INVOICE.label;
      else if (status === Constants.PaymentStatus.REJECTED.key)
        return Constants.VoucherType.CREDIT_NOTE.label;
    },
    onChange(files) {
      this.$v.files.$reset();
      this.files = files;
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      await this.$store.dispatch("app/loading", true);
      this.errors = [];
      const data = {code: this.code, files: this.files}
      try {
        const resp = await VoucherService.uploadVoucher(data);
        if (resp.payload.messages && resp.payload.messages.length > 0) {
          this.errors = resp.payload.messages;
          return;
        }
        this.$router.push({name: 'payments'});

      } catch (e) {
        this.errors = [];
        if (e.errors && e.errors.message)
          this.errors.push(e.errors.message)
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    }
  },
  async created() {
    try {
      await this.$store.dispatch("app/loading", true);
      const resp = await PaymentService.getPayment(this.code);
      this.payment = resp.payload;
    } catch (e) {
      console.error(e);
      this.$router.push({name: 'payments'});
    } finally {
      await this.$store.dispatch("app/loading", false);
    }
  }
};
</script>

<style lang="stylus" scoped>
@import '../Styles/voucher.styl';
</style>
