<template>
  <aside :class="`sidebar ${isMenuOpen ? '' : 'closeSidebar'}`">
    <section
      class="d-flex mt-1 mb-0 sidebar-item-title pl-0"
      @click="toggleMenu"
    >
      <div class="logo-icon"></div>
      <span class="sidebar-title w-50">NAGA</span>
    </section>
    <section>
      <ul>
        <router-link
          :to="{ name: 'profile' }"
          exact-active-class="active"
          class="d-flex sidebar-item text-decoration-none"
          @mouseover.native="tooltipType = 'profile-item'"
          @mouseleave.native="tooltipType = null"
        >
          <div class="d-flex align-items-center">
            <div class="sidebar-item-icon data-icon"></div>
            <span class="sidebar-item-text">Mis datos</span>
            <CustomTooltip
              custom-class="tooltip-sidebar"
              v-if="!isMenuOpen && tooltipType === 'profile-item'"
              text="Mis datos"
            />
          </div>
        </router-link>
        <router-link
          :to="{ name: 'payments' }"
          active-class="active"
          class="d-flex sidebar-item text-decoration-none"
          @mouseover.native="tooltipType = 'payments-item'"
          @mouseleave.native="tooltipType = null"
        >
          <div class="d-flex align-items-center">
            <div class="sidebar-item-icon payments-icon"></div>
            <span class="sidebar-item-text">Mis pagos</span>
            <CustomTooltip
              custom-class="tooltip-sidebar"
              v-if="!isMenuOpen && tooltipType === 'payments-item'"
              text="Mis pagos"
            />
          </div>
        </router-link>
        <router-link
            :to="{ name: 'change-password' }"
            active-class="active"
            class="d-flex sidebar-item text-decoration-none"
            @mouseover.native="tooltipType = 'change-password'"
            @mouseleave.native="tooltipType = null"
        >
          <div class="d-flex align-items-center">
            <div class="sidebar-item-icon pencil-icon"></div>
            <span class="sidebar-item-text">Cambiar contraseña</span>
            <CustomTooltip
                custom-class="tooltip-sidebar"
                v-if="!isMenuOpen && tooltipType === 'change-password'"
                text="Cambiar contraseña"
            />
          </div>
        </router-link>
      </ul>
    </section>
  </aside>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import CustomTooltip from "./CustomToolTip";

export default {
  name: "PageSidebar",
  components: { CustomTooltip },
  data() {
    return {
      showSideBar: true,
      categories: [],
      subcategories: [],
      tooltipType: null,
    };
  },
  methods: {
    ...mapMutations({
      setCurrentSubmenu: "app/setCurrentSubmenu",
      toggleMenu: "app/toggleMenu",
    }),
  },
  computed: {
    ...mapGetters({
      currentSubmenu: "app/getCurrentSubmenu",
      isMenuOpen: "app/isMenuOpen",
    }),
  },
  mounted: function () {},
};
</script>

<style scoped lang="stylus">
@import '../styles/pageSidebar.styl';
</style>
