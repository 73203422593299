import ProfileView from "../ProfileView.vue";
import ProfilePage from "../Pages/ProfilePage.vue";

const routes = [
  {
    path: '',
    component: ProfileView,
    children: [
      {
        path: '',
        name: 'profile',
        component: ProfilePage,
      },
    ]
  }
]

export default routes
