import PaymentsView from "../PaymentsView.vue";
import PaymentsPage from "../Pages/PaymentsPage.vue";
import UploadVoucherPage from "../Pages/UploadVoucherPage.vue";

const routes = [
  {
    path: '/payments',
    component: PaymentsView,
    children: [
      {
        path: '',
        name: 'payments',
        component: PaymentsPage,
      },
      {
        path: ':code/upload',
        name: 'upload-voucher',
        component: UploadVoucherPage,
        props: (route) => {
          const code = route.params.code;
          return {code}
        },
      },
    ]
  }
]

export default routes
