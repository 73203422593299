<template>
  <section>
    <Loader v-if="loading"/>

    <b-form class="form-login custom-form text-left mt-3" @submit.prevent="submit">
      <div class="form-group">
        <label for="password">Nueva contraseña *</label>
        <input
            id="password"
            type="password"
            v-model="form.password"
            autocomplete="off"
            maxlength="50"
            placeholder="Nueva contraseña"
            class="custom-form-control"
            :class="{
            'custom-form-control-error':
              $v.form.password.$error && !$v.form.password.required,
          }"
        />
        <form-error message="Contraseña es requerida"
                    v-if="$v.form.password.$error && !$v.form.password.required"></form-error>
        <form-error message="La contraseña debe tener 6 dígitos como mínimo"
                    v-if="$v.form.password.$error && !$v.form.password.minLength"></form-error>
        <form-error :message="error" v-if="error"></form-error>
      </div>

      <div class="form-group pb-0 mb-0">
        <label for="repeatPassword">Confirmar nueva contraseña *</label>
        <input
            id="repeatPassword"
            type="password"
            autocomplete="off"
            maxlength="50"
            v-model="form.repeatPassword"
            placeholder="Ingresa tu contraseña"
            class="custom-form-control"
            :class="{
            'custom-form-control-error':
              $v.form.repeatPassword.$error && !$v.form.repeatPassword.required,
          }"
        />
        <form-error
            message="Contraseña es requerida"
            v-if="$v.form.repeatPassword.$error && !$v.form.repeatPassword.required"
        ></form-error>
        <form-error message="Las contraseñas ingresadas no coinciden"
                    v-if="$v.form.repeatPassword.$error && !$v.form.repeatPassword.sameAs"></form-error>
      </div>
      <b-button
          class="mt-5 submit-button" type="submit" variant="none" block>Entrar
      </b-button>
    </b-form>
  </section>
</template>

<script>
import {minLength, required, sameAs} from "vuelidate/lib/validators";
import {SecurityService} from "@/core/services";
import {FormError, Loader} from "@/core/components";
import {Alert} from "@/core/utils";

export default {
  components: {
    FormError,
    Loader,
  },
  data() {
    return {
      form: {
        password: null,
        repeatPassword: null,
      },
      loading: false,
      error: null,
    };
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(6)
      },
      repeatPassword: {
        required,
        sameAs: sameAs(function () {
          return this.form.password
        })
      },
    },
  },
  methods: {
    async submit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      try {
        this.error = null;
        this.loading = true
        const data = {password: this.form.password, hash: this.$route.query.hash}
        await SecurityService.resetPassword(data);
        this.loading = false
        Alert.success('La contraseña ha sido actualizada correctamente')
        await this.$router.push({name: "login"});
      } catch (e) {
        if (e.errors && e.errors.message)
          this.error = e.errors.message
        else
          this.error = "Hubo un error al actualizar la contraseña";
      } finally {
        this.loading = false
      }
    },
  },
  computed: {},
};
</script>

<style lang="stylus" scoped>
@import '../Styles/form-login';
</style>
