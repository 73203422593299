<template>
  <section>
    <Loader v-if="loading"/>

    <b-form class="form-login custom-form text-left mt-3" @submit.prevent="submit">
      <div class="form-group">
        <label for="username">RUC</label>
        <input
            id="username"
            type="text"
            v-model.trim="form.username"
            autocomplete="off"
            placeholder="Ingresa tu RUC"
            class="custom-form-control"
            :class="{
            'custom-form-control-error':
              $v.form.username.$error && !$v.form.username.required,
          }"
        />
        <form-error message="RUC es requerido"
                    v-if="$v.form.username.$error && !$v.form.username.required"></form-error>
        <form-error :message="error" v-if="error"></form-error>
      </div>

      <div class="form-group pb-0 mb-0">
        <label for="password">Contraseña</label>
        <input
            id="password"
            type="password"
            autocomplete="off"
            v-model.trim="form.password"
            placeholder="Ingresa tu contraseña"
            class="custom-form-control"
            :class="{
            'custom-form-control-error':
              $v.form.password.$error && !$v.form.password.required,
          }"
        />
        <form-error
            message="Contraseña es requerida"
            v-if="$v.form.password.$error && !$v.form.password.required"
        ></form-error>
      </div>
      <b-button
          class="mt-5 submit-button" type="submit" variant="none" block>Entrar
      </b-button>
      <div class="text-center mt-3">
        <img
            src="@/core/assets/images/components/password.svg"
            alt="Icono de contraseña"
        />
        <a href="" class="password-reset ml-2" @click.prevent="$emit('reset')">¿Olvidaste tu contraseña?</a>
      </div>
    </b-form>
  </section>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import {SecurityService} from "@/core/services";

import FormError from "@/core/components/FormError";
import Loader from "@/core/components/Loader";

export default {
  components: {
    FormError,
    Loader,
  },
  data() {
    return {
      form: {
        username: null,
        password: null,
      },
      loading: false,
      error: null,
    };
  },
  validations: {
    form: {
      username: {
        required,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    async submit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      try {
        this.error = null;
        this.loading = true
        const resp = await SecurityService.login({...this.form});
        this.$store.dispatch("auth/setAccessToken", resp.token);
        const user = await this.$store.dispatch('auth/setUser');
        this.loading = false
        await this.$router.push({name: user.fullData ? "profile" : "welcome"});
      } catch (e) {
        if (e.errors && e.errors.message)
          this.error = e.errors.message
        else
          this.error = "Hubo un error al intentar autenticar";
      } finally {
        this.loading = false
      }
    },
  },

  computed: {},
};
</script>

<style lang="stylus" scoped>
@import '../Styles/form-login';
</style>
