<template>
  <section
      class="
      file-input
      d-flex
      flex-column flex-md-row
      justify-content-between
      align-items-center"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
  >
    <div class="file-drop align-items-center p-3" v-if="files.length > 0">
      <p class="file-message my-0 text-truncate" v-for="(file, i) in files" :key="i">
        <button class="bg-transparent border-0 delete-button" type="button" @click="deleteFile(i)">
          <img
              src="@/core/assets/images/components/close-icon.svg"
              alt="Cerrar modal"
          />
        </button>
        {{ file.name }}
      </p>
    </div>
    <div class="file-drop d-flex align-items-center p-3" v-else>
      <p class="file-message my-0">{{ 'Suelta tu archivos aquí...' }}</p>
    </div>

    <div class="file-button-container text-center">
      <section>
        <div class="file-input-container">
          <input
              type="file"
              :id="idFile"
              :multiple="multiple"
              @change="onChange"
              ref="file"
              :accept="acceptFile"
          />
        </div>
        <button type="button" @click="choseFile()" class="file-button">
          Buscar archivos
        </button>
      </section>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    idFile: {
      type: String,
      required: true,
    },
    acceptFile: {
      type: Array,
      required: true,
    },
    asset: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
      files: [],
    };
  },
  methods: {
    onChange() {
      if (!this.multiple)
        this.files = [];

      this.files.push(...Array.from(this.$refs.file.files));
      this.$emit("onChange", this.files);
    },
    dragover(event) {
      event.preventDefault();
    },
    dragleave() {
    },
    drop(event) {
      event.preventDefault();
      if (this.multiple) {
        this.$refs.file.files = event.dataTransfer.files;
        this.files.push(...Array.from(event.dataTransfer.files));
      } else {
        this.files = [];
        this.files.push(event.dataTransfer.files[0]);
        this.$refs.file.files = event.dataTransfer.files
      }
      this.$emit("onChange", this.files);
    },
    deleteFile(index) {
      this.files.splice(index, 1);
      this.$refs.file.value = null;
    },
    choseFile() {
      this.$refs.file.click();
    },
  },
  async created() {
  },
};
</script>

<style lang="stylus" scoped>
.delete-button
  img
    width 14px
    height 14px
    margin-top -2px
</style>

