import {http} from '@/http'

export default {
  getPayments() {
    return http.get('/payments')
  },
  getPayment(code) {
    return http.get(`/payments/${code}`)
  },
}
