import {http} from '@/http'
import { Util } from "@/core/utils";

export default {
  login(payload) {
    const formData = Util.getFormData(payload);
    return http.post('/auth', formData, {headers: { "Content-Type": "multipart/form-data" },})
  },
  isValidToken() {
    return http.get('/auth/token')
  },
  recoverPassword(data) {
    return http.post('/auth/recover-password', data)
  },
  resetPassword(data) {
    return http.post('/auth/reset-password', data)
  }
}
