<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Cambiar contraseña</h1>
    </div>
    <div class="container px-0">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8">
          <b-card
              tag="article"
              no-body
              style="max-width: 640px"
              class="custom-card"
          >
            <b-card-header
                header-tag="section"
                class="custom-card-header d-flex align-items-center"
            >
              <h3>
                Cambiar contraseña actual
              </h3>
            </b-card-header>
            <b-card-body class="custom-card-body">
              <form class="custom-form" @submit.prevent="save" novalidate>
                <div class="form-group">
                  <label for="current">Contraseña actual *</label>
                  <input
                      id="current"
                      type="password"
                      v-model.trim="form.currentPassword"
                      autocomplete="off"
                      maxlength="50"
                      placeholder="Ingresar su contraseña actual"
                      class="custom-form-control"
                      :class="{
                      'custom-form-control-error': $v.form.currentPassword.$error}"
                  />
                  <form-error
                      message="Contraseña actual es requerida"
                      v-if="$v.form.currentPassword.$error && !$v.form.currentPassword.required"
                  ></form-error>
                </div>
                <div class="form-group">
                  <label for="password">Contraseña nueva *</label>
                  <input
                      id="password"
                      type="password"
                      v-model.trim="form.newPassword"
                      autocomplete="off"
                      maxlength="50"
                      placeholder="Ingresar su contraseña nueva"
                      class="custom-form-control"
                      :class="{
                      'custom-form-control-error': $v.form.newPassword.$error}"
                  />
                  <form-error
                      message="Contraseña nueva es requerida"
                      v-if="$v.form.newPassword.$error && !$v.form.newPassword.required"
                  ></form-error>
                  <form-error message="La contraseña debe tener 6 dígitos como mínimo"
                              v-if="$v.form.newPassword.$error && !$v.form.newPassword.minLength"></form-error>
                </div>
                <div class="form-group">
                  <label for="new">Repite contraseña nueva *</label>
                  <input
                      id="repeat"
                      type="password"
                      v-model.trim="form.repeatPassword"
                      autocomplete="off"
                      maxlength="50"
                      placeholder="Ingresar su contraseña nueva"
                      class="custom-form-control"
                      :class="{
                      'custom-form-control-error': $v.form.repeatPassword.$error}"
                  />
                  <form-error
                      message="Contraseña nueva es requerida"
                      v-if="$v.form.repeatPassword.$error && !$v.form.repeatPassword.required"
                  ></form-error>
                  <form-error message="Las contraseñas ingresadas no coinciden"
                              v-if="$v.form.repeatPassword.$error && !$v.form.repeatPassword.sameAs"></form-error>
                  <form-error :message="error" v-if="error"></form-error>
                </div>
                <div class="form-group-buttons text-right">
                  <button class="button button-primary" type="submit">
                    Actualizar
                  </button>
                </div>
              </form>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {UserService} from "@/core/services";
import {Alert} from "@/core/utils";
import {minLength, required, sameAs} from "vuelidate/lib/validators";
import FormError from "@/core/components/FormError";


export default {
  data() {
    return {
      form: {
        currentPassword: null,
        newPassword: null,
        repeatPassword: null,
      },
      error: null
    };
  },
  components: {FormError},
  validations: {
    form: {
      currentPassword: {required},
      newPassword: {required, minLength: minLength(6)},
      repeatPassword: {
        required, sameAs: sameAs(function () {
          return this.form.newPassword
        })
      }
    },
  },
  computed: {},
  methods: {
    async save() {
      const self = this;
      self.$v.$touch();
      if (self.$v.$error) return;
      this.$v.form.$reset();
      this.error = null;
      const data = {...this.form};

      try {
        await UserService.updatePusherPassword(data);
        Alert.success("Se actualizó la contraseña correctamente");
      } catch (e) {
        if (e.errors && e.errors.message)
          this.error = e.errors.message
        else
          this.error = "Hubo un error al actualizar la contraseña";
      } finally {
        this.clear()
      }
    },
    clear() {
      this.form.currentPassword = null;
      this.form.newPassword = null;
      this.form.repeatPassword = null;
      this.$v.form.$reset()
    },
    async load(serieId) {
      try {
        this.$store.dispatch('app/loading', true);
        const response = await UserService.get(serieId);
        this.serie = {...response.payload};
        this.serie.voucherTypeId = response.payload.voucherType.id;
        this.editing = true;
      } catch (e) {
        console.error(e)
      } finally {
        this.$store.dispatch('app/loading', false);
      }
    },
  },
};
</script>

<style lang="stylus" scoped></style>



