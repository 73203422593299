import axios from "axios";
import store from '../../store'

function getFormData(data) {
  const formData = new FormData();
  for (const key in data) {
    if (Array.isArray(data[key]) && data[key].length > 0) {
      for (const index in data[key]) {
        const dataKeyIndex = data[key][index];
        if (typeof dataKeyIndex === 'string' || typeof dataKeyIndex === 'number') {
          formData.append(`${key}[${index}]`, data[key][index]);
        } else if (dataKeyIndex instanceof File) {
          formData.append(`${key}`, data[key][index]);
        } else {
          for (const key2 in data[key][index]) {
            formData.append(`${key}[${index}].${key2}`, data[key][index][key2] ? data[key][index][key2] : '');
          }
        }
      }
    } else {
      formData.append(key, data[key] ? data[key] : data[key] == null ? '' : data[key]);
    }
  }
  return formData;
}

function sliceText(text, maxCharacter) {
  return text.slice(0, maxCharacter)
}

function searchValue(key, object) {
  for (let val in object) {
    const obj = object[val];
    if (obj.key === key)
      return obj;
  }
  return {}
}

function formatNumber(val, fractionDigits = 2) {
  const d = parseFloat(val);
  if (!isNaN(d)) {
    return d.toLocaleString('en', {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits
    });
  } else {
    return '';
  }
}

function getFileName(response) {
  const contentDisposition = response.headers['content-disposition'];
  return contentDisposition.substr(contentDisposition.indexOf('filename=') + 9);
}

function titleCase(str) {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
}

async function downloadFile(url, fileName) {
  try {
    store.dispatch("app/loading", true);
    const response = await axios.get(url, {responseType: "blob"});
    let headers = response.headers;
    const blob = new Blob([response.data], {type: headers["content-type"]});
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(link.href);
  } catch (e) {
    console.error(e);
  } finally {
    store.dispatch("app/loading", false);
  }
}

export default {
  getFormData,
  sliceText,
  searchValue,
  formatNumber,
  getFileName,
  titleCase,
  downloadFile
}

