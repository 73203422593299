import {http} from '@/http'

export default {
  getPusherDetails() {
    return http.get('/users')
  },
  updatePusherDetails(data) {
    return http.put('/users', data)
  },
  updatePusherPassword(data) {
    return http.post('/users/password', data)
  },
}
