<template>
  <nav class="col-12 navbar nav-header py-0">
    <div class="col-12 d-flex align-items-center justify-content-end">
      <UserDropdown />
    </div>
  </nav>
</template>
<script>
import UserDropdown from '@/core/components/UserDropdown'

export default {
  name: "PageHeader",
  data() {
    return {
      showCart: false,
      categories: [],
      subcategories: []
    }
  },
  components: {
    UserDropdown
  },
  mounted: function () {
  }
}
</script>

<style scoped lang="stylus">
@import "../styles/pageHeader.styl"

</style>
