import Vue from 'vue'
import VueRouter from 'vue-router'
import ProfileRoutes from '@/modules/Profile/Routes'
import PaymentsRoutes from '@/modules/Payments/Routes'
import LoginRoutes from '@/modules/Login/Routes'
import WelcomeRoutes from '@/modules/Welcome/Routes'
import ConfigRoutes from '@/modules/Config/Routes'
import store from '../store'

Vue.use(VueRouter)

let routes = []

routes = routes.concat(ProfileRoutes, PaymentsRoutes, LoginRoutes, WelcomeRoutes, ConfigRoutes);


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach(async (to, from, next) => {
  if (to.meta.excludeAuth) {
    if (!store.getters['auth/isAuthenticated']) {
      next();
    } else {
      const user = await store.dispatch('auth/setUser');
      next({name: user.fullData ? 'profile' : 'welcome'})
    }

  } else {
    if (store.getters['auth/isAuthenticated']) {
      if (!store.getters['auth/isTokenValid']) {
        try {
          await store.dispatch('auth/checkValidToken')
          let user = store.getters['auth/getUser'];
          if (!user || !user.identifier)
            user = await store.dispatch('auth/setUser');
          if (!user.fullData && to.name !== 'welcome')
            next({name: 'welcome'})
          else if (user.fullData && to.name === 'welcome')
            next({name: 'profile'})
          else next();
        } catch (e) {
          console.error(e)
          next({name: 'login'});
        }
      } else {
        next();
      }
    } else {
      next({name: 'login'});
    }
  }
});

export default router
