import ConfigView from "../ConfigView.vue";
import ChangePasswordPage from "../Pages/ChangePasswordPage.vue";

const routes = [
  {
    path: '/config',
    component: ConfigView,
    children: [
      {
        path: 'password',
        name: 'change-password',
        component: ChangePasswordPage,
      }
    ]
  }
]

export default routes
