const Errors = {
  SERVER_ERROR: "Ha ocurrido un error interno, por favor pruebe nuevamente.",
  SERVER_UNAVAILABLE: "El servidor no está disponible, por favor pruebe nuevamente.",
  FIREBASE_AUTH: "Firebase not available.",
}

const PaymentStatus = {
  PENDING: {key: 1, label: 'PENDIENTE', color: 'badge-gray'},
  REVIEW: {key: 2, label: 'EN REVISIÓN', color: 'badge-yellow'},
  APPROVED: {key: 3, label: 'APROBADO', color: 'badge-green'},
  REJECTED: {key: 4, label: 'RECHAZADO', color: 'badge-red'},
  PAID: {key: 5, label: 'PAGADO', color: 'badge-purple'},
  INCORRECT: {key: 6, label: 'PAGO INCORRECTO', color: 'badge-red'}
}

const VoucherType = {
  INVOICE: {key: 1, label: 'Factura'},
  CREDIT_NOTE: {key: 2, label: 'Nota de crédito'},
}

const Currencies = {
  PEN: 'S/',
}


export default {Errors, PaymentStatus, VoucherType, Currencies}
