
const state = {
  menuOpen: true,
  currentSubmenu: localStorage.getItem('currentSubmenu') === null ? [] : localStorage.getItem('currentSubmenu').split(','),
  loading: false
};

const getters = {
  getCurrentSubmenu: state => state.currentSubmenu,
  isMenuOpen: state => state.menuOpen,
  isLoading: state => state.loading
};

const actions = {
  loading: (store, val) => {
    store.commit('setLoading', val)
  },
};

const mutations = {
  setCurrentSubmenu(state, value) {
    const values = state.currentSubmenu
    state.currentSubmenu = values.includes(value) ? values.filter(e => e !== value) : [...values, value]
    localStorage.setItem('currentSubmenu', state.currentSubmenu)
  },
  toggleMenu(state) {
    if (!window.matchMedia("(max-width: 768px)").matches) {
      state.menuOpen = !state.menuOpen
    }
  },
  setMenu(state, value) {
    state.menuOpen = value
  },
  setLoading(state, value) {
    state.loading = value
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
