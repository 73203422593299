
const modalMixin = {
    props: {
        show: {
            type: Boolean,
            default: false
        },
    },
    data: function () {
        return {
            showLocal: false,
        }
    },
    methods: {
        hideModal(){
            this.$emit('hide');
        },
        hidden(){
            this.$emit('hidden');
        }
    },
    watch:{
        show(newVal){
            this.showLocal = newVal;
        }
    },
    async created() {
        this.showLocal = this.show;
    }
}

export default modalMixin
