const USER_KEY = 'user';
const ACCESS_TOKEN_KEY = 'accessToken';

function getUser() {
  const user = localStorage.getItem(USER_KEY) || '{}'
  return JSON.parse(user)
}

function setUser(user) {
  localStorage.setItem(USER_KEY, JSON.stringify(user))
}

function clearUser() {
  localStorage.removeItem(USER_KEY)
}

function setAccessToken(accessToken) {
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
}

function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
}

function clearAccessToken() {
  localStorage.removeItem(ACCESS_TOKEN_KEY)
}

function clearAll() {
  localStorage.clear();
}

export default {
  getUser,
  setUser,
  clearUser,
  getAccessToken,
  setAccessToken,
  clearAccessToken,
  clearAll,
}
