<template>
  <div class="login d-flex justify-content-center align-items-center">
    <div class="container animate__animated animate__fadeIn">
      <section class="row justify-content-center">
        <div class="col-11 col-md-6 col-lg-4 text-center">
          <b-card class="login-card mx-auto border-0 py-4">
          <img src="@/core/assets/images/logo.svg" alt="Logo Auren" class="logo"/>
            <h1 class="title">Generar una nueva contraseña</h1>
            <h2 class="subtitle">
              Para continuar debes ingresar los siguientes datos
            </h2>
            <FormResetPassword @reset="showFormReset = true"/>
          </b-card>

          <section class="text-center new-account">
            <p>© {{ currentYear }} Forjado por Wize</p>
          </section>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import FormResetPassword from '../Components/FormResetPassword'

export default {
  name: "ResetPasswordPage",
  components: {
    FormResetPassword
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      showFormReset: false
    };
  }
};
</script>

<style lang="stylus" scoped>
@import "../Styles/login";
</style>
