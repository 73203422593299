<template>
  <footer class="admin-footer">
      <div class="d-flex justify-content-between align-items-center">
        <span class="text">V{{ version }} {{ year }}</span>
        <p class="text text-dark font-weight-bold my-0">Forjado por Wize</p>
      </div>
  </footer>
</template>

<script>
export default {
  name: "PageFooter",
  data() {
    return {
      version: process.env.PACKAGE_VERSION,
      year: new Date().getFullYear()
    };
  },
};
</script>

<style lang="stylus" scoped>
@import "../styles/components/page-footer";
</style>
