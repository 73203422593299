import {LocalStorage} from '../core/utils'
import {UserService, SecurityService} from '../core/services'

const state = {
  accessToken: LocalStorage.getAccessToken(),
  user: LocalStorage.getUser(),
  isTokenValid: false,
}

const getters = {
  isAuthenticated: state => state.accessToken,
  getUser: state => state.user,
  isTokenValid: state => state.isTokenValid,
};

const actions = {
  setAccessToken({commit}, payload) {
    commit('isTokenValid', true)
    commit('accessToken', payload);
  },
  setUser({commit,dispatch}) {
    return new Promise((resolve, reject) => {
      UserService.getPusherDetails().then((resp) => {
        commit('user', resp.payload);
        resolve(resp.payload)
      }).catch((ex) => {
        console.error(ex);
        dispatch('logout');
        reject();
      })

    })
  },
  checkValidToken({dispatch, commit}){
    return new Promise((resolve, reject) => {
      SecurityService.isValidToken()
        .then(response => {
          if (response) {
            commit('isTokenValid', true)
            resolve()
          }
        }).catch(err => {
        reject(err);
        dispatch('logout')
      });
    });
  },
  logout({commit}) {
    return new Promise((resolve) => {
      commit('accessToken', null)
      commit('user', null)
      commit('isTokenValid', false)
      resolve()
    })
  }
};

const mutations = {
  isTokenValid(state, value){
    state.isTokenValid = value;
  },
  accessToken(state, value) {
    value ? LocalStorage.setAccessToken(value) : LocalStorage.clearAccessToken();
    state.accessToken = value;
  },
  user(state, value) {
    value ? LocalStorage.setUser(value) : LocalStorage.clearUser();
    state.user = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
