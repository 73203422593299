<template>
  <form class="search-bar position-relative" :class="{'search-bar--focus': focus}"  @submit.prevent="search">
      <img :src="focus ? searchBarIconOpacity : searchBarIcon" alt="Icono para búsqueda" class="icon">
      <input type="search" v-model="keyword" :placeholder="placeholder" class="field border-0" @focus="focus = true" @blur="focus = false">
  </form>
</template>

<script>
import searchBarIcon from '@/core/assets/images/components/search-bar.svg'
import searchBarIconOpacity from '@/core/assets/images/components/search-bar-opacity.svg'

export default {
    data() {
        return {
            focus: false,
            searchBarIcon,
            searchBarIconOpacity,
          keyword: null
        }
    },
    props: {
        placeholder: String
    },
  methods: {
    search() {
        this.$emit("search", this.keyword);
      }
    }
}
</script>

<style lang="stylus" scoped>
@import '../../styles/components/search-bar'
</style>
