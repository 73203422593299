<template>
  <span
    class="error"
  >
    <img
      src="@/core/assets/images/components/error-form.svg"
      alt="Seleccionar"
      class="error-icon"
    />
    {{ message }}
  </span>
</template>

<script>
export default {
    props: {
        message: String
    }
};
</script>

<style lang="stylus" scoped>
@import '../styles/components/form-error.styl';
</style>