<template>
  <div id="app">
    <Loader v-if="isLoading"/>
    <router-view/>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import Loader from '@/core/components/Loader'

export default {
  components: {
    Loader
  },
  computed: {
    ...mapGetters({
      isLoading: 'app/isLoading'
    })
  }
}
</script>
<style lang="stylus">
</style>
