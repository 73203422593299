import Vue from 'vue'
import '@/core/plugins/vuelidate'
import '@/core/plugins/directives'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/core/plugins/bootstrap'
import '@/core/styles/app.styl'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
